<template>
  <div id="app">
    <router-view :key="$route.name" />
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
});
</script>

<style>

</style>
