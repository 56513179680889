<template>
  <div>
    Connexion...
  </div>
</template>

<script>
export default {
  name: 'ADCallback',
  props: {
    code: { type: String, required: true },
    state: { type: String, required: true },
  },
  async mounted() {
    if (!this.code) {
      alert('error code is missing');
    }
    return this.callback();
  },
  methods: {
    async callback() {
      try {
        await this.$store.dispatch('auth/signInRedirectCallbackOidc', {
          providerName: this.$VueAuthPlugin.azureADOidcProviderName,
        });
        return this.$router.push({ name: 'AdminDashboard' });
      } catch (e) {
        this.$message.error('Une erreur est survenue...');
        console.log('ERROR', e);
        return null;
      }
    },
  },
};
</script>
