






import Vue from 'vue';

export default Vue.extend({
  name: 'ShopLogoutPage',
  created() {
    // const storePrefix = this.$VueShopAuthPlugin.shopAuthStoreModulePrefix;
    const storePrefix = 'shopAuth';
    const redirectTo = '/connexion-magasin';
    this.$store.dispatch(`${storePrefix}/signOut`).then(() => {
      setTimeout(() => {
        this.$router.push(redirectTo);
      }, 2000);
    });
  },
});
