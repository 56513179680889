import ShopLoginPage from '@/views/pages/ShopLoginPage.vue';
import ShopLogoutPage from '@/views/pages/ShopLogoutPage.vue';
import ShopMagicLinkPage from '@/views/pages/ShopMagicLinkPage.vue';
import CallbackADPage from '@/views/pages/CallbackADPage.vue';
import AdminLoginPage from '@/views/pages/AdminLoginPage.vue';
import LogoutPage from '@/views/pages/LogoutPage.vue';
import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/oidc/callback-ad',
    name: 'ADOidcCallback',
    component: CallbackADPage,
    props: (route: any) => ({
      code: route.query.code,
      state: route.query.session_state,
    }),
    meta: {},
  },

  // CUSTOM LOGIN LOGOUT ROUTES FOR SHOP
  {
    path: '/connexion-magasin',
    alias: '/login',
    name: 'ShopLogin',
    component: ShopLoginPage,
    meta: {
      shopGuestRequired: true,
    },
  },
  {
    path: '/deconnexion-magasin',
    name: 'ShopLogout',
    component: ShopLogoutPage,
  },

  // CUSTOM LOGIN LOGOUT ROUTES FOR ADMIN
  {
    path: '/connexion-admin',
    name: 'AdminLogin',
    component: AdminLoginPage,
    meta: {
      userGuestRequired: true,
    },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutPage,
  },

  // MAGIC LINK LOGIN
  {
    path: '/magic-link-verification',
    name: 'ShopMagicLinkLogin',
    props: (route) => ({
      magicLinkToken: route.query.token,
    }),
    component: ShopMagicLinkPage,
  },

  // BO - BACK OFFICE
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: () => import('@/layouts/BackOfficeLayout.vue'),
    redirect: { name: 'BackOfficePage' },
    children: [
      {
        path: 'back-office',
        name: 'BackOfficePage',
        component: () => import('@/views/pages/AdminProductsPage.vue'),
        meta: {
          userAuthRequired: true,
        },
      },
    ],
  },

  // APP
  {
    path: '/app',
    name: 'UserDashboard',
    component: () => import('@/layouts/AppLayout.vue'),
    redirect: { name: 'PreparateurPage' },
    children: [
      {
        path: '/preparateur',
        name: 'PreparateurPage',
        component: () => import('@/views/pages/UserCommonPage.vue'),
        meta: {
          shopAuthRequired: true,
        },
      },
      {
        path: '/boulanger',
        name: 'BoulangerPage',
        component: () => import('@/views/pages/UserCommonPage.vue'),
        meta: {
          shopAuthRequired: true,
        },
      },
    ],
  },
  // CONFIG
  {
    path: '/config',
    name: 'OpeningConfiguration',
    component: () => import('@/layouts/UserConfigLayout.vue'),
    redirect: { name: 'OpeningConfigurationPreparateur' },
    children: [
      {
        path: '/config/opening/preparateur',
        name: 'OpeningConfigurationPreparateur',
        component: () => import('@/views/pages/RayonConfiguration.vue'),
        meta: {
          shopAuthRequired: true,
        },
      },
      {
        path: '/config/opening/boulanger',
        name: 'OpeningConfigurationBoulanger',
        component: () => import('@/views/pages/RayonConfiguration.vue'),
        meta: {
          shopAuthRequired: true,
        },
      },
    ],
  },

  {
    path: '/*',
    redirect: { name: 'UserDashboard' },
  },
];

export default routes;
