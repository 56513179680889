<template>
  <div
    class="shop-ml-verification-page"
    data-test="shop-ml-verification__page"
  >
    Chargement...
    <div data-test="shop-ml-verification__page" />
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'ShopMagicLinkPage',
  props: {
    magicLinkToken: { type: String, required: true },
  },
  async mounted() {
    await this.handleMagicLinkToken(this.magicLinkToken);
  },
  methods: {
    showErrorMessage() {
      this.$toast.error('Jeton magasin non reconnu.', {
        transition: 'Vue-Toastification__fade',
        newestOnTop: true,
        position: 'top-center',
        timeout: false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        icon: true,
      });
    },
    showSuccessMessage() {
      this.$toast.success('Magasin connecté !', {
        transition: 'Vue-Toastification__fade',
        newestOnTop: true,
        position: 'top-center',
        timeout: 5000,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        icon: true,
      });
    },
    async handleMagicLinkToken(token) {
      try {
        // const storePrefix = this.$VueShopAuthPlugin.shopAuthStoreModulePrefix;
        const storePrefix = 'shopAuth';
        // const providerName = this.$VueShopAuthPlugin.shopAuthStoreModuleProviderName;
        const providerName = 'portail-outils-shop-auth';
        await this.$store.dispatch(`${storePrefix}/signInWithToken`, { shopToken: token, providerName });
        this.showSuccessMessage();
        // const redirectTo = this.$VueShopAuthPlugin.afterLoginRoute;
        const redirectTo = '/';
        return this.$router.push(redirectTo);
      } catch (e) {
        this.showErrorMessage();
        return null;
      }
    },
  },
});
</script>

<style scoped>
.shop-ml-verification-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
