<template>
  <div class="full-page auth">
    <Modal>
      <h1 class="title title--3">
        Bienvenue sur l’application<br> Prev Prod
      </h1>
      <Button
        block
        theme="outline"
        size="lg"
        :loading="ADConnecting"
        @click.native="loginWithAD"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
        >
          <title>MS-SymbolLockup</title>
          <rect
            x="0"
            y="0"
            width="8"
            height="8"
            fill="#f25022"
          />
          <rect
            x="0"
            y="9"
            width="8"
            height="8"
            fill="#00a4ef"
          />
          <rect
            x="9"
            y="0"
            width="8"
            height="8"
            fill="#7fba00"
          />
          <rect
            x="9"
            y="9"
            width="8"
            height="8"
            fill="#ffb900"
          />
        </svg>
        <div>Je me connecte avec Azure AD</div>
      </Button>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'AdminLoginPage',
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      show: true,
      ADConnecting: false,
    };
  },
  methods: {
    loginWithAD() {
      this.ADConnecting = true;
      this.$store.dispatch('auth/signInRedirectOidc', {
        providerName: 'azure-ad-oidc',
        options: { shopToken: null },
      });
    },
  },
};
</script>

<!--<style>-->
<!--@import "../../theme.css";-->
<!--@import "../../styles.css";-->
<!--</style>-->
