export default {
  namespaced: true,
  state: {
    selectedDate: null,
    isSendingExpectedRevenue: false,
    isSendingPrevisions: false,
    isValidatingPrevisions: false,
    isPrevisionValidated: false,
    isSendingMorningRevenue: false,
    isSendingMorningSales: false,
    isValidatingMidday: false,
    isMiddayValidated: false,
    reCalculatedRevenue: 0,
  },
  getters: {
    isSendingExpectedRevenue(state) {
      return state.isSendingExpectedRevenue;
    },
    isSendingPrevisions(state) {
      return state.isSendingPrevisions;
    },
    isValidatingPrevisions(state) {
      return state.isValidatingPrevisions;
    },
    isPrevisionValidated(state) {
      return state.isPrevisionValidated;
    },
    isSendingMorningRevenue(state) {
      return state.isSendingMorningRevenue;
    },
    isSendingMorningSales(state) {
      return state.isSendingMorningSales;
    },
    isValidatingMidday(state) {
      return state.isValidatingMidday;
    },
    isMiddayValidated(state) {
      return state.isMiddayValidated;
    },
    reCalculatedRevenue(state) {
      return state.reCalculatedRevenue;
    },
  },
  actions: {
    sendingExpectedRevenue: ({ commit }, isSending) => {
      commit('SET_IS_SENDING_EXPECTED_REVENUE', isSending);
    },
    validatingPrevisions: ({ commit }, isValidating) => {
      commit('SET_IS_VALIDATING_PREVISIONS', isValidating);
    },
    setPrevisionValidationStatus: ({ commit }, isValidated) => {
      commit('SET_IS_PREVISION_VALIDATED', isValidated);
    },
    sendingMorningRevenue: ({ commit }, isSending) => {
      commit('SET_IS_SENDING_MORNING_REVENUE', isSending);
    },
    validatingMidday: ({ commit }, isValidating) => {
      commit('SET_IS_VALIDATING_MIDDAY', isValidating);
    },
    setMiddayValidationStatus: ({ commit }, isValidated) => {
      commit('SET_IS_MIDDAY_VALIDATED', isValidated);
    },
    reCalculateRevenue: ({ commit }, products) => {
      const productsWithSummary = products.map((product) => (product));
      const sum = productsWithSummary.reduce((previousSum, current) => {
        const { quantityPerPlate, unitPrice, plannedQuantity } = current;
        if (!quantityPerPlate || !unitPrice || !plannedQuantity) {
          return previousSum;
        }
        return previousSum + (quantityPerPlate * unitPrice * plannedQuantity);
      }, 0);
      commit('SET_RE_CALCULATED_REVENUE', sum);
    },
  },
  mutations: {
    SET_IS_SENDING_EXPECTED_REVENUE(state, isSending) {
      state.isSendingExpectedRevenue = isSending;
    },
    SET_IS_VALIDATING_PREVISIONS(state, isValidating) {
      state.isValidatingPrevisions = isValidating;
    },
    SET_IS_PREVISION_VALIDATED(state, isValidated) {
      state.isPrevisionValidated = isValidated;
    },
    SET_IS_SENDING_MORNING_REVENUE(state, isSending) {
      state.isSendingMorningRevenue = isSending;
    },
    SET_IS_VALIDATING_MIDDAY(state, isValidating) {
      state.isValidatingMidday = isValidating;
    },
    SET_IS_MIDDAY_VALIDATED(state, isValidated) {
      state.isMiddayValidated = isValidated;
    },
    SET_RE_CALCULATED_REVENUE(state, value) {
      state.reCalculatedRevenue = value;
    },
  },
};
