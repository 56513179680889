<template>
  <button
    class="button"
    :class="extraClasses"
    :type="type"
    :disabled="disabled"
  >
    <template v-if="loading">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="button__loader">
        <path
          d="M377.831369,80.1144564 C394.237052,63.8665792 422.711884,63.9710672
        439.013072,80.3756763 C511.323471,153.256025 531.647709,257.430517 492.148676,352.305581
        C452.388406,447.755329 362.784119,507 258.237075,507 C153.010816,507 62.204838,448.330013
        21.242878,353.872901 C-19.0921132,260.87862 -1.17125573,157.174324 68.0565466,83.1968511
        C83.730766,66.4265342 112.466835,65.4861426 129.186002,81.1593359 C146.636633,97.511701
        147.524839,124.887545 131.223651,142.337034 C85.9251567,190.662714 74.2217396,258.579885
        100.606676,319.444119 C127.61858,381.771185 188.016572,420.483973 258.184828,420.483973
        C340.631222,420.483973 391.781425,368.083263 412.262405,318.973923 C438.072619,256.960322
        424.80178,188.886418 377.570132,141.292155 C360.74647,124.365106 360.903212,96.8847733
        377.831369,80.1144564 Z"
        />
      </svg>
    </template>
    <template v-else>
      <slot />
    </template>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'md',
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraClasses() {
      return [
        this.block ? 'button--block' : '',
        `button--${this.size}`,
        `button--${this.theme}`,
      ];
    },
  },
};
</script>

<style scoped>
.button {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-1-2, 8px);
  appearance: none;
  border: 0;
  font-weight: 600;
  line-height: 1.125;
  background-color: var(--color-richblack-100, #1a1a1a);
  color: var(--color-white, #ffffff);
  border-radius: var(--border-radius-regular, 4px);
  transition: background-color 120ms cubic-bezier(0.37, 0, 0.63, 1);
}
.button:hover {
  background-color: var(--color-richblack-60, #484848);
  cursor: pointer;
}
.button:focus {
  background-color: var(--color-richblack-60, #484848);
  outline: none;
}

.button:active {
  transform: translateY(1px);
}

.button[disabled] {
  opacity: .64;
  pointer-events: none;
}

.button::after {
  content: '';
  pointer-events: none;
  position: absolute;
  z-index: -1;
  top: calc(var(--size-1-4, 4px) * -1);
  right: calc(var(--size-1-4, 4px) * -1);
  bottom: calc(var(--size-1-4, 4px) * -1);
  left: calc(var(--size-1-4, 4px) * -1);
  border: var(--size-1-4, 4px) solid transparent;
  border-radius: calc(var(--border-radius-regular, 4px) + 2px);
  opacity: 0;
  transition: opacity 120ms cubic-bezier(0.37, 0, 0.63, 1);
}
.button:focus::after {
  opacity: .32;
}

/* Button sizes */
.button--block {
  display: flex;
  width: 100%;
}
.button--sm {
  font-size: var(--text-sm, 14px);
  padding: var(--size-1-2, 8px) var(--size-1, 16px);
}
.button--md {
  font-size: var(--text-md, 16px);
  padding: var(--size-1-2, 8px) var(--size-1, 16px);
}
.button--lg {
  font-size: var(--text-md, 16px);
  padding: calc(var(--size-1, 16px) * 0.875) var(--size-2, 32px);
}

/* Button primary */
.button--primary {
  background-color: var(--color-primary, #1368ff);
color: var(--color-primary-invert, #ffffff);
}
.button--primary:hover,
.button--primary:focus {
  background-color: var(--color-primary-light, #4b85ff);
}
.button--primary::after {
  border-color: var(--color-primary-lighter, #79a3ff);
}

/* Button crumb */
.button--secondary {
  background-color: var(--color-crumb-100, #d1d1d1);
  color: var(--color-mocha-100, #1a1a1a);
}
.button--secondary:hover,
.button--secondary:focus {
  background-color: var(--color-crumb-40, #dddddd);
}
.button--secondary::after {
  border-color: var(--color-mocha-40, #d1d1d1);
}

/* Button default outline */
.button--outline {
  background: transparent;
  color: var(--color-richblack-100, #1a1a1a);
  border: 1px solid currentColor;
}
.button--outline:hover,
.button--outline:focus {
  background: transparent;
  color: var(--color-richblack-60, #484848);
  border-color: var(--color-richblack-40, #484848);
}
.button--outline::after {
  color: var();
  border-color: var(--color-richblack-40, #484848);
}

/* Button primary - outline */
.button--primary-outline {
  background: transparent;
  border: 1px solid var(--color-primary, #1368ff);
  color: var(--color-primary, #1368ff);
}
.button--primary-outline:hover,
.button--primary-outline:focus {
  background: transparent;
  border-color: var(--color-primary-light, #4b85ff);
  color: var(--color-primary-light, #4b85ff);
}
.button--primary-outline::after {
  border-color: var(--color-primary-lighter, #4b85ff);
}

@keyframes spin {
  from { transform:rotate(0deg); }
  to { transform:rotate(360deg); }
}

.button__loader {
  fill: currentColor;
  width: 1rem;
  height: 1rem;
  animation: spin 1000ms linear infinite;
}

.button svg {
  fill: currentColor;
}
</style>
