<template>
  <div class="modal">
    <div class="logo" aria-hidden />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Modal',
};
</script>

<style scoped>
.modal {
  width: 100%;
  max-width: 28rem;
  max-height: 75vh;
  margin: 2rem auto;
  margin-inline: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: calc(var(--size-1) * 0.875);
  overscroll-behavior: contain;
  background: var(--color-white, #ffffff);
  border-radius: var(--border-radius-large, 8px);
  padding: 2rem;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.04),
    0px 6px 8px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(0, 0, 0, 0.04),
    0px 2px 2px rgba(0, 0, 0, 0.04),
    0px 1px 1px rgba(0, 0, 0, 0.08);
}
.modal .logo {
  flex: 0 0 7rem;
  display: block;
  width: 7rem;
  height: 7rem;
  margin-top: calc((2rem + 3.5rem) * -1);
  margin-inline: auto;
}
</style>
