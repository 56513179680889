<template>
  <div data-test="logout__page">
    Déconnexion...
  </div>
</template>
<script>
export default {
  async created() {
    try {
      await this.$store.dispatch('shopAuth/signOut');
    } catch {
      console.log('Shop logout error');
    }
    try {
      await this.$store.dispatch('auth/signOut');
    } catch (e) {
      console.log('Auth logout error');
    }

    return this.$router.push({ name: 'ShopLogin' });
  },
};
</script>
