import Vue from 'vue';
import Transitions from 'vue2-transitions';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { SaveIcon } from '@vue-hero-icons/outline';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/fr';

// auth
// import { VueShopAuthPlugin } from '@holdingblachere/vue-shop-auth-plugin';
import { VueAuthPlugin } from '@holdingblachere/vue-auth-plugin';
import { VueAclPlugin } from '@holdingblachere/vue-acl-plugin';
import permissions from '@/permissions';
import App from './App.vue';
import router from './router';
import store from './store';
// i18n
import i18n from './i18n/i18n';
// auth
import '@holdingblachere/vue-shop-auth-plugin/dist/vue-shop-auth-plugin.css';
import '@holdingblachere/vue-auth-plugin/dist/vue-auth-plugin.css';

import './assets/css/tailwind.css';

Vue.use(ElementUI, { locale });

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
  position: 'bottom-right',
  timeout: false,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: SaveIcon,
  rtl: false,
});

Vue.use(Transitions);

// Vue.use(VueShopAuthPlugin, {
//   store,
//   router,
//   shopAuthStoreModulePrefix: 'shopAuth',
//   shopAuthStoreModuleProviderName: 'portail-outils-shop-auth',
//   afterLoginRoute: { path: '/' },
//   afterLogoutRoute: { name: 'ShopLogin' },
// });

Vue.use(VueAuthPlugin, {
  store,
  router,
  applicationName: 'PrevProd',
  shopAuthStoreModuleProviderName: 'portail-outils-shop-auth',
  azureADOidcProviderName: 'azure-ad-oidc',
  // monCompteOidcProviderName: 'mon-compte-oidc',
  disableShopAuthentication: true,
});

Vue.use(VueAclPlugin, {
  permissions,
  currentUserRoleGetter: () => store.getters['auth/authenticatedUserRole'],
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  async beforeCreate() {
    await this.$store.dispatch('shopAuth/initState');
    await this.$store.dispatch('auth/initState');
  },
  render: (h) => h(App),
}).$mount('#app');
