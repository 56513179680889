// en, de, fr, pt
export default {
  fr: {
    commons: {
      saveUpdate: 'Sauvegarder les modifications',
      cancelAndGoBack: 'Annuler et revenir en arrière',
      confirmPopoverMessage: 'Êtes-vous sûr(e) ?',
      confirmButtonText: 'OK',
      cancelButtonText: 'Annuler',
      previousButtonText: 'Précédant',
      nextButtonText: 'Suivant',
      defaultErrorMessage: 'Une erreur est survenue',
      datepicker: 'Choisissez une date',
    },
    errors: {
      GENERAL: 'Une erreur s\'est produite. Ouvrez un ticket si cela se reproduit',
    },
    loginPage: {
      title: 'Connectez-vous',
      usernameLabel: 'Nom utilisateur',
      passwordLabel: 'Mot de passe',
      submitButtonText: 'Se connecter',
      successMessage: 'Vous êtes connecté(e)',
    },
    permissions: {
      notAuthorized: 'Vous n\'avez pas le rôle nécessaire pour accéder à cette application',
      connectWithOtherAccount: 'Se connecter avec un autre compte',
    },
    mainNav: {
      appTitle: 'APP NAME',
      home: 'Accueil',
      preparateur: 'Préparateur',
      boulanger: 'Boulanger',
      logoutMenuItem: 'Déconnexion',
    },
    status: {
      label: 'Statut',
      published: 'Publié',
      draft: 'Brouillon',
    },
  },
};
