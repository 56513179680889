import Vue from 'vue';
import VueRouter from 'vue-router';
import { GuardFactory } from '@holdingblachere/vuex-auth';
import { GuardFactory as ShopGuardFactory } from '@holdingblachere/vuex-shop-auth';
import store from '@/store';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

const authGuard = GuardFactory.CreateGuardMiddleware({
  store,
  prefix: 'auth',
  authenticatedRedirectRoute: 'AdminDashboard',
  guestRedirectRoute: 'AdminLogin',
});

const shopAuthGuard = ShopGuardFactory.CreateGuardMiddleware({
  store,
  prefix: 'shopAuth',
  authenticatedRedirectRoute: 'UserDashboard',
  guestRedirectRoute: 'ShopLogin',
});

router.beforeEach(authGuard);
router.beforeEach(shopAuthGuard);

export default router;
