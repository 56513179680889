import Vue from 'vue';
import Vuex from 'vuex';
import {
  MonCompteShopAuthProvider,
  StoreFactory as ShopAuthStoreFactory,
} from '@holdingblachere/vuex-shop-auth';
import { /* MonCompteOidcProvider, */ StoreFactory, AzureActiveDirectoryOidcProvider } from '@holdingblachere/vuex-auth';
import storePrevisions from './store-previsions';
import productPrevisions from './product-previsions';

Vue.use(Vuex);

const portailOutilsShopAuthProvider = new MonCompteShopAuthProvider('portail-outils-shop-auth', {
  baseUrl: process.env.VUE_APP_PORTAIL_OUTILS_BACKEND_URL,
  storage: localStorage,
  jwtPublicKey: process.env.VUE_APP_SHOPS_PUBLIC_KEY.split('\\n').join('\n'),
});

const azureADOidcProvider = new AzureActiveDirectoryOidcProvider('azure-ad-oidc', {
  authority: process.env.VUE_APP_AZURE_AD_AUTHORITY,
  clientId: process.env.VUE_APP_AZURE_AD_OIDC_CLIENT_ID,
  redirectUri: `${process.env.VUE_APP_FRONTEND_URL}/oidc/callback-ad`,
  responseType: 'code',
  scope: 'openid profile',
});

export default new Vuex.Store({
  state: {
    selectedDate: null,
  },
  getters: {
    selectedDate(state) {
      return state.selectedDate;
    },
    isAsyncOperationInProgress(state, getters, rootState, rootGetters) {
      return rootGetters['storePrevisions/isSendingExpectedRevenue']
      || rootGetters['productPrevisions/isSendingPrevisions']
      || rootGetters['storePrevisions/isSendingMorningRevenue']
      || rootGetters['productPrevisions/isSendingMorningSales']
      || rootGetters['storePrevisions/isValidatingPrevisions']
      || rootGetters['storePrevisions/isValidatingMidday'];
    },
  },
  actions: {
    setSelectDate: ({ commit }, date) => {
      commit('SET_SELECTED_DATE', date);
    },
  },
  mutations: {
    SET_SELECTED_DATE(state, date) {
      state.selectedDate = date;
    },
  },
  modules: {
    storePrevisions,
    productPrevisions,
    shopAuth: ShopAuthStoreFactory.CreateStoreModule([
      portailOutilsShopAuthProvider,
    ]),
    auth: StoreFactory.CreateStoreModule([
      // monCompteOidcProvider,
      azureADOidcProvider,
    ]),
  },
});
