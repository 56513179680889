export default {
  namespaced: true,
  state: {
    isSendingPrevisions: false,
    isSendingMorningSales: false,
  },
  getters: {
    isSendingPrevisions(state) {
      return state.isSendingPrevisions;
    },
    isSendingMorningSales(state) {
      return state.isSendingMorningSales;
    },
  },
  actions: {
    sendingPrevisions: ({ commit }, isSending) => {
      commit('SET_IS_SENDING_PREVISIONS', isSending);
    },
    sendingMorningSales: ({ commit }, isSending) => {
      commit('SET_IS_SENDING_MORNING_SALES', isSending);
    },
  },
  mutations: {
    SET_IS_SENDING_PREVISIONS(state, isSending) {
      state.isSendingPrevisions = isSending;
    },
    SET_IS_SENDING_MORNING_SALES(state, isSending) {
      state.isSendingMorningSales = isSending;
    },
  },
};
